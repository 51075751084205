<script setup lang="ts">
import { DS_ICONS, DsPageHeader } from '@dreamshaper/ds-ui'
import DsSidebar from '../components/DsSidebar/DsSidebar.vue'

type LayoutProps = {
  classContainer?: string | Record<string, boolean>
}

useHead({
  htmlAttrs: {
    class: 'light',
  },
})

const { org, customer } = useUser()
const route = useRoute()

const getName = () => {
  if (route.name?.includes('org')) {
    return org.value?.subdomain
  }

  return customer.value?.name
}

const getType = () => {
  if (route.name?.includes('org')) {
    return 'org'
  }

  return 'customer'
}

const pageName = ref<string>(getName() || '')
const pageType = ref<'org' | 'customer'>(getType())

const previousRoute = ref<string | null>(null)
const currentFullPath = ref<string | null>(null)

const props = defineProps<LayoutProps>()

const config = useRuntimeConfig()
const typeNavigation: any = config.public.env

const structure = computed<any[]>(() => {
  const optionsStructure = []

  optionsStructure.push({
    label: 'Org Dashboard',
    icon: DS_ICONS.ChevronLeft,
    childs: [],
    component: resolveComponent('NuxtLink'),
    componentProps: {
      to: `/org/${org.value?.id}/home`,
    },
  })

  optionsStructure.push({
    label: 'Admin OPS',
    childs: [
      {
        label: 'Org Settings',
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/org/${org.value?.id}/edit`,
        },
        currentPage: false,
      },
      {
        label: 'Users',
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/org/${org.value?.id}/user`,
        },
        currentPage: false,
      },
      {
        label: 'Projects',
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/org/${org.value?.id}/project`,
        },
        currentPage: false,
      },
      {
        label: 'Clusters',
        childs: [
          {
            label: 'All',
            component: resolveComponent('NuxtLink'),
            componentProps: {
              to: `/org/${org.value?.id}/cluster`,
            },
            currentPage: false,
          },
          {
            label: 'Categories',
            component: resolveComponent('NuxtLink'),
            componentProps: {
              to: `/org/${org.value?.id}/cluster/categories`,
            },
            currentPage: false,
          },
        ],
        currentPage: false,
      },
      {
        label: 'Evaluation',
        component: resolveComponent('NuxtLink'),
        componentProps: {
          to: `/org/${org.value?.id}/evaluation`,
        },
        currentPage: false,
      },
      {
        label: 'Reports',
        badgeText: 'In Dev',
        component: resolveComponent('NuxtLink'),
        currentPage: false,
      },
    ],
    currentPage: false,
  })

  if (org.value?.multipath_support === true) {
    optionsStructure.push({
      label: 'Ds Builder',
      childs: [
        {
          label: 'Paths',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${org.value?.id}/builder/path`,
          },
          currentPage: false,
        },
        {
          label: 'Outputs',
          component: resolveComponent('NuxtLink'),
          componentProps: {
            to: `/org/${org.value?.id}/builder/output`,
          },
          currentPage: false,
        },
        {
          label: 'Resources',
          badgeText: 'In Dev',
          component: resolveComponent('NuxtLink'),
        },
      ],
      currentPage: false,
    })
  }

  optionsStructure.forEach((item) => {
    const currentPageRoute = currentFullPath.value
    const itemRoute = item.componentProps?.to

    if (currentPageRoute && itemRoute && currentPageRoute.startsWith(itemRoute)) {
      if (!(itemRoute === '/org' && currentPageRoute === `/org/${org.value?.id}/home`)) {
        item.currentPage = true

        let parent = item
        while (parent) {
          parent.currentPage = true
          parent = (parent as any).parent
        }
      }
    } else if (item.childs) {
      item.childs.forEach((child) => {
        const childRoute = child.componentProps?.to
        if (currentPageRoute && childRoute && currentPageRoute.startsWith(childRoute)) {
          child.currentPage = true

          let parent = item
          while (parent) {
            parent.currentPage = true
            parent = (parent as any).parent
          }
        } else if ((child as any).childs) {
          (child as any).childs.forEach((grandchild: any) => {
            const grandchildRoute = grandchild.componentProps?.to
            if (currentPageRoute && grandchildRoute && currentPageRoute.startsWith(grandchildRoute)) {
              grandchild.currentPage = true

              let parent = child
              while (parent) {
                parent.currentPage = true
                parent = (parent as any).parent
              }

              let grandparent = item
              while (grandparent) {
                grandparent.currentPage = true
                grandparent = (grandparent as any).parent
              }
            }
          })
        }
      })
    }
  })

  return optionsStructure
})

const shouldRenderPageHeader = computed(() => {
  const isOrgRoute = route.name?.includes('org-orgId')
  const isCustomerRoute = route.name?.includes('customer-customerId')

  const hiddenRoutes = [
    'org-orgId-edit',
    'customer-customerId-edit',
  ]

  const isHiddenRoute = hiddenRoutes.some(hiddenRoute =>
    route.name?.includes(hiddenRoute),
  )

  return (isOrgRoute || isCustomerRoute) && !isHiddenRoute
})

onMounted(() => {
  previousRoute.value = route.fullPath
  currentFullPath.value = route.fullPath
})

watch(
  () => route.fullPath,
  (newRoute, oldRoute) => {
    previousRoute.value = oldRoute
    currentFullPath.value = newRoute
  },
)

watch([() => route.name, org, customer], () => {
  pageName.value = getName() || ''
  pageType.value = getType()
}, { deep: true })
</script>

<template>
  <main style="background-color: #e9eef3">
    <section class="admin-builder-layout flex min-h-screen h-full">
      <DsSidebar
        name="DS Builder"
        :type="typeNavigation"
        :header-icon="DS_ICONS.Tools"
        :show-env-name="false"
        :structure="structure"
      >
        <div class="w-full pl-4 pr-2">
          <DsPageHeader
            v-if="shouldRenderPageHeader"
            v-memo="[pageName, pageType]"
            :page-name="pageName"
            :type="pageType"
          />
        </div>
      </DsSidebar>

      <section class="flex flex-col w-full ml-[12.5rem]">
        <section class="flex flex-col w-full h-full items-center gap-14">
          <Header />

          <Container :class="props.classContainer">
            <slot name="container" />
          </Container>
        </section>
      </section>
    </section>

    <div id="settings-root" />
  </main>
</template>

<style lang="scss">
.admin-builder-layout {
  .ds-navigation {
    .sidebar {
      .header > div {
        .ds-icon > svg {
          width: 1.875rem;
          height: 1.875rem;
        }
      }
    }
  }
}
</style>
